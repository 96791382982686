import React, { forwardRef } from 'react'

import { Box, MenuItem, TextField, TextFieldProps } from '@mui/material'

import { ArrowDownIcon } from '../../assets/index'
import { palette } from '../../styles/theme'

interface SelectOption {
  value: string
  label: string
}

interface InputSelectProps {
  options: SelectOption[]
  isDark?: boolean
}

export type InputSelectCustomProps = TextFieldProps & InputSelectProps

const InputSelectBase: React.ForwardRefRenderFunction<
  any,
  InputSelectCustomProps
> = ({ options, isDark, ...rest }, ref) => {
  const PaperProps = isDark
    ? {
        style: {
          background: palette.secondary.main,
          color: 'white',
        },
      }
    : {}

  return (
    <TextField
      aria-live="polite"
      sx={
        isDark
          ? {
              '& .MuiSelect-select': {
                backgroundColor: `${palette.secondary.main} !important`,
                borderRadius: '8px',
                color: 'white',
                overflow: 'hidden',
                whiteSpace: 'normal',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: palette.primary.main,
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
            }
          : {}
      }
      select
      ref={ref}
      autoComplete="off"
      SelectProps={{
        autoComplete: 'new-password',
        IconComponent: ArrowDownIcon,
        MenuProps: {
          style: {
            maxWidth: '300px',
          },
          PaperProps,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        },
      }}
      {...rest}
    >
      {options.map((option: any) => (
        <MenuItem key={option.value} value={option.value}>
          <Box
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {option.label}
          </Box>
        </MenuItem>
      ))}
    </TextField>
  )
}

const InputSelect = forwardRef(InputSelectBase)

export default InputSelect
